import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/register-business',
    name: 'register-business',
    component: () => import('@/views/register_interface/NewBusiness'),
    meta: {
      title: 'New Business',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/register-individual',
    name: 'register-individual',
    component: () => import('@/views/register_interface/NewBusiness'),
    meta: {
      title: 'New Individual',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-member',
    name: 'list-of-member',
    component: () => import('@/views/report_interface/Members/ListOfMember'),
    meta: {
      title: 'New Individual',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment_interface/MainInterface'),
    meta: {
      title: 'Payments',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-loan',
    name: 'new-loan',
    component: () => import('@/views/loan_interface/NewLoan'),
    meta: {
      title: 'New Loan',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-loan',
    name: 'list-of-loan',
    component: () => import('@/views/loan_interface/report_interface/ListOfLoan'),
    meta: {
      title: 'List Of Loan',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-deductions-loan',
    name: 'monthly-deductions-loan',
    component: () => import('@/views/loan_interface/report_interface/MontlyDeductionReport'),
    meta: {
      title: 'Monthly Deduction Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-summary-deductions-loan',
    name: 'monthly-summary-deductions-loan',
    component: () => import('@/views/loan_interface/report_interface/MontlySummaryDeductionReport'),
    meta: {
      title: 'Monthly Summary Deduction Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-due-loan',
    name: 'monthly-due-loan',
    component: () => import('@/views/loan_interface/report_interface/MontlyDueReport'),
    meta: {
      title: 'Monthly Due Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-collection-loan',
    name: 'monthly-collection-loan',
    component: () => import('@/views/loan_interface/report_interface/MontlyCollectionReport'),
    meta: {
      title: 'Monthly Collection Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/delinquent-account-loan',
    name: 'delinquent-account-loan',
    component: () => import('@/views/loan_interface/report_interface/ListOfDeliquentAccount'),
    meta: {
      title: 'List Of Delinquent Account',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/loan-payment',
    name: 'loan-payment',
    component: () => import('@/views/loan_interface/payment_interface/MainInterface'),
    meta: {
      title: 'Loan Payment',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/nmapi-share-payment',
    name: 'nmapi-share-payment',
    component: () => import('@/views/nmapi_share_payment_interface/MainInterface'),
    meta: {
      title: 'NMAPI Share Payment',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/remittances',
    name: 'remittances',
    component: () => import('@/views/loan_interface/RemittancesReport'),
    meta: {
      title: 'Remittances Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/search-info',
    name: 'search-info',
    component: () => import('@/views/search_interface/SearchMembers'),
    meta: {
      title: 'Search Members',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
